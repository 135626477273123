import "./index.scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import { $ } from "@olmokit/dom";

/**
 * Component: Accessori
 */
export function SliderProduct(n = "", loop = true) {
  console.log("Accessori mounted.");

  // Glidecontrol();
  const glideOptions = {
    type: "carousel",
    focusAt: 0,
    startAt: 0,
    perView: 4,
    hoverpause: false,
    gap: 30,
    loop: loop,
    animationDuration: 800,
    breakpoints: {
      1024: {
        perView: 2,
        startAt: 0,
        gap: 15,
        peek: {
          before: 0,
          after: 0
        },
      },
      600: {
        perView: 1,
        gap: 0,
        peek: {
          before: 0,
          after: 0
        }
      }
    }    
  };
  // Glidecontrol();
  const sliderContainer = $(".SliderProduct"+n);
  const sliders = glide(sliderContainer, glideOptions);

  sliders.mount({
    Swipe,
    LazyLoad,
    Anchors,
    Controls,
    Breakpoints,
    Autoplay
  });

}
