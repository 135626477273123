import { Hamb } from "./baseheader";
// import { I18nLinks } from "@olmokit/core/i18n/links";
import { Selectlang } from "components/Selectlang";
import { SearchBtn } from "components/SearchBtn";
import { HeaderMobile } from "components/HeaderMobile";
import "components/Hamburger";
// import "utils/logo.scss";
import "./index.scss";
import { $all, $, forEach, addClass, removeClass, on } from "@olmokit/dom";
import { scrollThreshold } from "@olmokit/core/scroll/threshold";

const button = document.querySelector('#productsBtn');
const menu = document.querySelector('div[for="products"]');

on(button, "click", () => {
	if(menu.classList.contains('hidden')){
		removeClass(menu, 'hidden');
	}else{
		addClass(menu, 'hidden');
	}
});

  // Function to check window width and update content based on the device
function isMobile() {
  // Get the window width
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  // Check if the window width is less than a certain breakpoint (e.g., 768 pixels)
  if (windowWidth < 768) {
    // Update content for mobile view
    console.log('mobile View');
    return true;
  } else {
    // Update content for desktop view
    console.log('Desktop View');
    return false;
    
  }
}
  
function countSubdirectories() {
  try {
    // Get the pathname from the current URL
    const pathname = window.location.pathname;

    // Remove leading and trailing slashes, then split the pathname into an array
    const pathSegments = pathname.replace(/^\/|\/$/g, '').split('/');

    // Filter out empty segments (resulting from multiple slashes)
    const subdirectories = pathSegments.filter(segment => segment !== '');

    // Return the count of subdirectories
    return subdirectories.length;
  } catch (error) {
    console.error('Error:', error.message);
    return -1; // Return -1 to indicate an error
  }
}

/**
 * Component: Header
 *
 */
export function Header() {
  SearchBtn();
  Hamb();
  // I18nLinks();
  HeaderMobile();
  Selectlang();
  // checkWindowWidth();

  if (!isMobile){
    scrollThreshold(-1, { target: $('.Header:') });
  }else{
    scrollThreshold(55, { target: $('.Header:') });
  }
  
  // window.addEventListener('resize', checkWindowWidth);

  if(countSubdirectories() <= 1){
    var body = document.body;
    body.style.marginTop = '0px';
  }
  
  return {};
}
