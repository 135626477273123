import "./index.scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import { $ } from "@olmokit/dom";

/**
 * Component: Accessori
 */
export function SliderHome() {
  console.log("Accessori mounted.");

  // Glidecontrol();
  const glideOptions = {
    type: "carousel",
    focusAt: 0,
    startAt: 0,
    perView: 1,
    hoverpause: false,
    loop: true,
    animationDuration: 2000,
  };
  // Glidecontrol();
  const sliderContainer = $(".SliderHome");
  const sliders = glide(sliderContainer, glideOptions);

  sliders.mount({
    Swipe,
    LazyLoad,
    Anchors,
    Controls,
    Breakpoints,
    Autoplay
  });

}
