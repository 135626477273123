import "./index.scss";
import { $, on } from "@olmokit/dom";

/**
 * Component: SearchBtn
 */


export function SearchBtn() {

  const openBtn = $('a[data-target="#search"]');
  const actionButton = $('#searchBtn');
  const Input = $('#inputSearch');
  const CloseBtn = $('#closesearch');
  const link = $('#linksearch').textContent;

  function search(){
    window.location.href = link.slice(0, -1) + "?q=" + Input.value;
  }

  on(openBtn, 'click', function(event) {
    event.preventDefault();
    document.getElementById("search-box").classList.add("-open");
    setTimeout(function() {
      document.getElementById("inputSearch").focus();
    }, 0);
  })

  on(CloseBtn, 'click', function(event) {
    event.preventDefault();
    console.log('aiuto')
    document.getElementById("search-box").classList.remove("-open");
  });

  // document.querySelector('a[data-target="#close"]').addEventListener('click', );

  // document.querySelector('.searchboxcontainer').addEventListener('click', function(event) {
  //   event.preventDefault();
  //   // document.getElementById("search-box").classList.remove("-open");
  // });

  // document.querySelector('#search-box').addEventListener('click', function(event) {
  //   event.preventDefault();
  //   document.getElementById("search-box").classList.remove("-open");
  // });

  document.addEventListener('keyup', function(e) {
    if (e.keyCode == 13) { // escape key maps to keycode `27`
      document.getElementById("search-box").classList.remove("-open");
      search();
    }
  });

  on(actionButton, 'click', function(e){
    search();
  })

  console.log("SearchBtn mounted.");
}
