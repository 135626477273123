/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";
import {SliderProduct} from "components/Slider/Product";
import {SliderHome} from "components/Slider/Home";

console.log("Route home/index.js mounted.");
SliderProduct(); 
SliderHome(); 

function mobileDetect() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/ipad/i) !== null || ua.match(/iphone/i) !== null || ua.match(/android/i) !== null) {
        return "device";
    } else {
        return "desktop";
    }
}


function cards() {
    var catCards = document.querySelectorAll('.catCard');

    function touchStartHandler() {
        this.classList.add('active');
    }

    function touchEndHandler() {
        this.classList.remove('active');
    }

    function mouseEnterHandler() {
        this.classList.add('active');
    }

    function mouseLeaveHandler() {
        this.classList.remove('active');
    }

    if (mobileDetect() !== 'desktop') {
        catCards.forEach(function(card) {
            card.addEventListener('touchstart', touchStartHandler);
            card.addEventListener('touchend', touchEndHandler);
        });
    } else {
        catCards.forEach(function(card) {
            card.addEventListener('mouseenter', mouseEnterHandler);
            card.addEventListener('mouseleave', mouseLeaveHandler);
        });
    }
}

function inputHidden() {
    var h1Elements = document.querySelectorAll('h1.h3');

    if (h1Elements.length > 0) {
        var value = h1Elements[0].textContent;
        console.log(value);
        document.getElementById('hiddenIdform').value = value;
    }
}

document.addEventListener('DOMContentLoaded', function() {
    inputHidden();
    cards();

});

window.addEventListener('resize', function() {
    cards();
    // moreCards();
});
